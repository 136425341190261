import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebook } from "@fortawesome/free-brands-svg-icons";
import "./SignIn.css";

const SignIn = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [error, setError] = useState("");

  useEffect(() => {
    if (currentUser) {
      navigate("/vocabulary");
    }
  }, [currentUser, navigate]);

  const handleSignIn = async (provider) => {
    try {
      await setPersistence(auth, browserLocalPersistence);
      await signInWithPopup(auth, provider);
      navigate("/vocabulary");
    } catch (error) {
      if (error.code === "auth/account-exists-with-different-credential") {
        setError(
          "An account already exists with the same email address but different sign-in credentials. Use a different sign-in method."
        );
      } else {
        setError("Failed to sign in. Please try again later.");
      }
    }
  };

  return (
    <div className="container">
      <h1 className="title">BoxWord</h1>
      {error && <p className="error">{error}</p>}
      <div className="buttonWrapper">
        <button
          className="button"
          onClick={() => handleSignIn(new GoogleAuthProvider())}
        >
          <FontAwesomeIcon icon={faGoogle} className="icon" />
          <span className="buttonText">Sign in with Google</span>
        </button>
        <button
          className="button"
          onClick={() => handleSignIn(new FacebookAuthProvider())}
        >
          <FontAwesomeIcon icon={faFacebook} className="icon" />
          <span className="buttonText">Sign in with Facebook</span>
        </button>
      </div>
    </div>
  );
};

export default SignIn;
