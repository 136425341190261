import { firestore } from "../firebaseConfig";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

const wordsCollectionRef = collection(firestore, "words");
const vocabulariesCollectionRef = collection(firestore, "vocabularies");

export const fetchWords = async (userId) => {
  const q = query(wordsCollectionRef, where("userId", "==", userId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const addWord = async (wordData, userId) => {
  const docRef = await addDoc(collection(firestore, "words"), {
    ...wordData,
    userId,
  });
  return docRef; // Return the document reference
};

export const deleteWord = async (wordId) => {
  await deleteDoc(doc(firestore, "words", wordId));
};

export const addToDailyBox = async (wordId, userId) => {
  const cardBoxRef = collection(firestore, "cardboxes");
  // Query for a card box with interval = 0 for the user
  const q = query(
    cardBoxRef,
    where("userId", "==", userId),
    where("interval", "==", 0)
  );
  const querySnapshot = await getDocs(q);

  // Update existing card box with the new word's ID, avoiding duplicates
  const cardBox = querySnapshot.docs[0];
  const updatedWords = [...new Set([...cardBox.data().words, wordId])]; // Ensure uniqueness
  await updateDoc(doc(firestore, "cardboxes", cardBox.id), {
    words: updatedWords,
  });
};

export const fetchVocabularies = async (userId) => {
  const q = query(vocabulariesCollectionRef, where("userId", "==", userId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};
