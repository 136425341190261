import React, { useState, useEffect } from "react";
import { fetchVocabularies } from "../../services/vocabularyService";
import { useAuth } from "../../contexts/AuthContext";
import "./AddWordForm.css";

const AddWordForm = ({ addWord }) => {
  const [original, setOriginal] = useState("");
  const [translation, setTranslation] = useState("");
  const [vocabularies, setVocabularies] = useState([]);
  const [selectedVocabulary, setSelectedVocabulary] = useState("none");
  const [isLanguageSelected, setIsLanguageSelected] = useState(true); // New state for language selection validation
  const { currentUser } = useAuth();

  useEffect(() => {
    const loadVocabularies = async () => {
      if (currentUser) {
        const fetchedVocabularies = await fetchVocabularies(currentUser.uid);
        setVocabularies(fetchedVocabularies);
      }
    };

    loadVocabularies();
  }, [currentUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedVocabulary === "none") {
      setIsLanguageSelected(false); // Set validation state to false if language not selected
      return;
    }

    await addWord({ original, translation, vocabularyId: selectedVocabulary });
    setOriginal("");
    setTranslation("");
    setIsLanguageSelected(true); // Reset validation state
  };

  return (
    <form onSubmit={handleSubmit} className="addWordForm">
      <select
        value={selectedVocabulary}
        onChange={(e) => {
          setSelectedVocabulary(e.target.value);
          setIsLanguageSelected(true); // Reset validation state when user selects a language
        }}
        className={!isLanguageSelected ? "inputError" : ""} // Apply error styling conditionally
        required
      >
        <option value="none" disabled>
          Select a language
        </option>
        {vocabularies.map((vocabulary) => (
          <option key={vocabulary.id} value={vocabulary.id}>
            {vocabulary.title}
          </option>
        ))}
      </select>
      <input
        type="text"
        value={original}
        onChange={(e) => setOriginal(e.target.value)}
        placeholder="Original"
        required
      />
      <input
        type="text"
        value={translation}
        onChange={(e) => setTranslation(e.target.value)}
        placeholder="Translation"
      />
      <button type="submit">Add Word</button>
    </form>
  );
};

export default AddWordForm;
