import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import AddWordForm from "../AddWordForm/AddWordForm";
import { auth } from "../../firebaseConfig";
import {
  fetchWords,
  addWord,
  addToDailyBox,
} from "../../services/vocabularyService";
import "./VocabularyPage.css";

const VocabularyPage = () => {
  const [words, setWords] = useState([]);
  const [notification, setNotification] = useState({ message: "", type: "" }); // 'success' or 'error'
  const [showNotification, setShowNotification] = useState(false);

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      fetchWords(currentUser.uid).then(setWords);
    }
  }, [currentUser]);

  const handleAddWord = async (wordData) => {
    const wordExists = words.some(
      (word) => word.original === wordData.original
    );
    if (wordExists) {
      setNotification({
        message: "Word already exists in your vocabulary.",
        type: "warning",
      });
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 2500);
      return;
    }

    try {
      const docRef = await addWord(wordData, currentUser.uid);
      const newWordId = docRef.id;
      setWords((prevWords) => [...prevWords, { id: newWordId, ...wordData }]);

      await addToDailyBox(newWordId, currentUser.uid);

      setNotification({ message: "Word added successfully!", type: "success" });
      setShowNotification(true);
    } catch (error) {
      console.error("Error adding word: ", error);
      setNotification({
        message: "Failed to add word. Please try again.",
        type: "error",
      });
      setShowNotification(true);
    }
    setTimeout(() => setShowNotification(false), 2500);
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <div className="vocabularyPage">
      <h1 className="title">BoxWord</h1>
      <div className="content">
        <div className="addWordForm">
          <AddWordForm addWord={handleAddWord} />
        </div>
      </div>
      <span className="signOutText" onClick={handleSignOut}>
        Sign out
      </span>
      <div
        className={`notification ${notification.type} ${
          showNotification ? "show" : ""
        }`}
      >
        {notification.message}
      </div>
    </div>
  );
};

export default VocabularyPage;
