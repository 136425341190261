import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAz9_L8AHCFO23PI4rpKWCeSjR-7OwoEBk",
  authDomain: "wordbox-df3cd.firebaseapp.com",
  projectId: "wordbox-df3cd",
  storageBucket: "wordbox-df3cd.appspot.com",
  messagingSenderId: "394219681995",
  appId: "1:394219681995:web:8f499dea93a81cd6a4034e",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, firestore };
